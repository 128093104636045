// ChatApp.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import ContactList from '../components/ContactList';
import ChatWindow from '../components/ChatWindow';
import InfoPanel from '../components/InfoPanel';
import TopBar from '../components/TopBar';
import PAPA from '../components/PAPA';
import './ChatApp.css';
import { useAppState } from '../AppStateContext'; // Import useAppState

function ChatApp() {
  const { isPapaVisible } = useAppState(); // Use the state from context

  return (
    <div className="home">
      <div className="left-sidebar">
        <Sidebar />
        {isPapaVisible && (
          <div className="PAPA">
            <PAPA />
          </div>
        )}
      </div>
      <div className="content">
        <TopBar />
        <ChatWindow />
      </div>
      <div className="right-sidebar">
        <InfoPanel />
      </div>
    </div>
  );
}

export default ChatApp;
