import React from 'react';

const MainHeader = ({ onFilterChange, selectedFilters, selectedSection }) => {
    return (
        <div className="header-styling">
            <div className="main-title">{selectedSection}</div>
            <div className="main-buttons">
                <button
                    className={`res-button ${selectedFilters.includes('Resource') ? 'selected' : ''}`}
                    onClick={() => onFilterChange('Resource')}>
                    Resources
                </button>
                <button
                    className={`prog-button ${selectedFilters.includes('Program') ? 'selected' : ''}`}
                    onClick={() => onFilterChange('Program')}>
                    Programs
                </button>
            </div>
        </div>
    );
};

export default MainHeader;
