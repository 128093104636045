import React, { useState, useEffect } from 'react';
import './PAPA.css';
import Card from './Card';
import MainHeader from './MainHeader';
import { useAppState } from '../AppStateContext';
import { database } from '../firebase';
import { ref, onValue, set } from "firebase/database";

const PAPA = () => {
    const [cardsData, setCardsData] = useState([]);
    const [visibleCardsCount, setVisibleCardsCount] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState(["Resource", "Program"]);
    const [selectedSidebarButton, setSelectedSidebarButton] = useState('all');
    const [selectedSection, setSelectedSection] = useState('Spotlight');

    const { selectedConversation } = useAppState();

    const setCardsDataFromFirebase = () => {
        const messagesRef = ref(database, `PAPA/${selectedConversation}`);
        const unsubscribe = onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                // Ensure that the data is an array and filter out any undefined entries
                const dataArray = Object.values(data).filter(item => item !== undefined);
                setCardsData(dataArray);
            } else {
                // Default data if none is found in Firebase
                setCardsData([
                    { id: 1, type: "Resource", impact: "true", kind: "Please Select", saved: "false", title: "Select a conversation first", tags: [], link: "https://peersupport.io" }
                ]);
            }
        });
        return () => unsubscribe();
    };

    useEffect(() => {
        const unsubscribe = setCardsDataFromFirebase();
        return () => unsubscribe();
    }, [selectedConversation]);

    const handleFilterChange = (filter) => {
        setSelectedFilters(prev => {
            return prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter];
        });
    };

    const handleSidebarButtonSelect = (button) => {
        setSelectedSidebarButton(button);
        setVisibleCardsCount(1); // Reset visible cards count when switching modes
        setSelectedSection(button === 'saved' ? 'Saved' : 'Spotlight');
    };

    const toggleSave = (cardid) => {
        if (cardsData.length === 1 && cardsData[0].title === "Select a conversation first") {
            console.log("Default data is presented, toggleSave is disabled.");
            return;  // This will exit the function without making any changes or attempting to update Firebase
        }

        const updatedCardsData = cardsData.map(card =>
            card.id === cardid ? { ...card, saved: card.saved === "true" ? "false" : "true" } : card
        );
        setCardsData(updatedCardsData);
    
        const updatedCard = updatedCardsData.find(card => card.id === cardid);
        if (updatedCard) {
            // Assuming each card's data is stored under its ID within `messages/{selectedConversation}/PAPA/{cardid}`
            const specificCardRef = ref(database, `PAPA/${selectedConversation}/${cardid}`);
    
            // Using set() to replace the data at this node, only updating the 'saved' status
            set(specificCardRef, updatedCard)
                .then(() => {
                    console.log("Card updated successfully in Firebase:", cardid);
                })
                .catch(error => {
                    console.error("Error updating card in Firebase:", error);
                });
        } else {
            console.error('No card found with id:', cardid);
        }
    };    

    const toggleLink = (link) => {
        window.open(link, '_blank');
    };

    const filteredCards = cardsData.filter(card => 
        (selectedSidebarButton === 'saved' ? card.saved === "true" : true) && selectedFilters.includes(card.type)
    );

    return (
        <div className="top-resource">
            <div className="drawing-border"></div>
            <div className="PAPA-Bulk">
            <div className="PAPA-Sidebar"> 
                <div className={`sidebar-button ${selectedSidebarButton === 'all' ? 'selected' : ''}`} onClick={() => handleSidebarButtonSelect('all')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <path d="M9.2229 4.53082L7.34568 6.4074C6.30953 7.44418 4.62866 7.44418 3.59252 6.4074C3.42922 6.24475 3.30158 6.06136 3.18946 5.87215L4.06166 4.99994C4.10313 4.95814 4.15433 4.93417 4.20325 4.90599C4.2635 5.11206 4.3688 5.3071 4.53081 5.46909C5.04822 5.98684 5.8903 5.98619 6.40739 5.46909L8.28397 3.59251C8.80171 3.07476 8.80171 2.23302 8.28397 1.7156C7.76686 1.19818 6.92513 1.19818 6.40739 1.7156L5.73995 2.38368C5.19856 2.17276 4.61504 2.11607 4.04707 2.19932L5.46908 0.777309C6.50586 -0.259144 8.18609 -0.259144 9.22288 0.777309C10.259 1.8138 10.259 3.49437 9.2229 4.53082ZM4.26027 7.61656L3.59252 8.28464C3.0751 8.80175 2.23302 8.80175 1.71561 8.28464C1.19819 7.76689 1.19819 6.92515 1.71561 6.40742L3.59252 4.53084C4.11027 4.01309 4.95168 4.01309 5.4691 4.53084C5.63077 4.69252 5.73607 4.88756 5.79699 5.09329C5.84624 5.06478 5.89679 5.04146 5.93826 4.99998L6.81047 4.12811C6.69901 3.93826 6.5707 3.75551 6.40741 3.59255C5.37127 2.5561 3.69039 2.5561 2.65392 3.59255L0.777339 5.46913C-0.259113 6.50591 -0.259113 8.18614 0.777339 9.22293C1.81379 10.2591 3.49436 10.2591 4.53083 9.22293L5.95317 7.80059C5.38487 7.88417 4.80135 7.82715 4.26027 7.61656Z" fill="url(#paint0_linear_4151_7)"/>
                            <defs>
                                <linearGradient id="paint0_linear_4151_7" x1="8.26675" y1="2.03954" x2="2.63173" y2="6.52953" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#79BBE0"/>
                                <stop offset="0.620958" stop-color="#C87FC0" stop-opacity="0.647762"/>
                                <stop offset="1" stop-color="#FF7878" stop-opacity="0.4"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={`sidebar-button ${selectedSidebarButton === 'saved' ? 'selected' : ''}`} onClick={() => handleSidebarButtonSelect('saved')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                            <path d="M2.5 1C1.67157 1 1 1.67157 1 2.5V9.44223C1 10.2868 1.98215 10.751 2.63474 10.215L4.68263 8.53276C4.86708 8.38124 5.13292 8.38124 5.31737 8.53276L7.36526 10.215C8.01785 10.751 9 10.2868 9 9.44223V2.5C9 1.67157 8.32843 1 7.5 1H2.5Z" stroke="#686EA5"/>
                            <path d="M2.5 1C1.67157 1 1 1.67157 1 2.5V9.44223C1 10.2868 1.98215 10.751 2.63474 10.215L4.68263 8.53276C4.86708 8.38124 5.13292 8.38124 5.31737 8.53276L7.36526 10.215C8.01785 10.751 9 10.2868 9 9.44223V2.5C9 1.67157 8.32843 1 7.5 1H2.5Z" stroke="url(#paint0_linear_4151_41)"/>
                            <defs>
                                <linearGradient id="paint0_linear_4151_41" x1="-1.5" y1="12" x2="9.5" y2="0.499999" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FDB8C1"/>
                                <stop offset="0.55" stop-color="#DAA2D2"/>
                                <stop offset="1" stop-color="#7ABCE1"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="PAPA-Content">
                    <MainHeader onFilterChange={handleFilterChange} selectedFilters={selectedFilters} selectedSection={selectedSection} />
                    <div className="resource-cards">
                        {filteredCards.slice(0, visibleCardsCount).map(card => (
                            <Card key={card.id} {...card} toggleSave={toggleSave} toggleLink={toggleLink} />
                        ))}
                        {visibleCardsCount < filteredCards.length && (
                            <div className="reload" onClick={() => setVisibleCardsCount(prev => prev + 1)}>
                                <img src="reloadicon.png" alt="Load More" />
                                <span>Load more</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PAPA;
