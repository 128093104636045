// SafetyPlan.js
import React, { useState } from 'react';
import './SafetyPlan.css';

const SafetyPlan = () => {
  const [selected, setSelected] = useState([]);

  const toggleSelected = (item) => {
    setSelected((prevSelected) =>
      prevSelected.includes(item) ? prevSelected.filter((i) => i !== item) : [...prevSelected, item]
    );
  };

  const [isCopingContainerExpanded, setIsCpoingContainerExpanded] = useState(false);

  const toggleCopingContainer = () => {
    setIsCpoingContainerExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="top-container">
      <div className="header">
        <img src="safety-plan-icon.png" alt="Safety Plan" />
        <h4>Safety Plan</h4>
      </div>

      <div className="content-container">
          <div className="content-header">
            <img src="CopingDrop.png" alt="ACE Dimensions" onClick={toggleCopingContainer} className="dropdown-icon" />
            <p>RELEVANT GROUNDING TOOLS</p>
          </div>
          
          {isCopingContainerExpanded && (
            <><p>Nature walks, Music, Creative journaling/Doodling, Breathing exercises with visualizing breath as varying colours.</p>
            <img src="Find_Button.png" className="find-button" ></img></>
          )}
      </div>

      <div className="grid-container">
        <div className="grid-item">
          <h3>Support Network</h3>
          <h5>Sister</h5>
        </div>
        <div className="grid-item">
          <h3>Key Activators</h3>
          <h5>Neglect, Abuse</h5>
        </div>
        <div className="grid-item">
          <h3>Contact</h3>
          <h5>Need to inquire</h5>
        </div>
      </div>


      {/* ...rest of your component */}
    </div>
  );
};

export default SafetyPlan;
