// TopBar.js
import React, { useEffect, useState } from 'react';
import './TopBar.css';
import { useAuth } from '../AuthContext';


const TopBar = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState(null);

  useEffect(() => {
    if (!currentUser) {
      setName("");
    } else if (currentUser.email == "darren.mastropaolo@kidshelpphone.ca") {
      setName("Darren");
    } else if (currentUser.email == "jocelyn.rankin@kidshelpphone.ca") {
      setName("Jocelyn");
    } else if (currentUser.email == "maia.norman@vectorinstitute.ai") {
      setName("Maia");
    } else if (currentUser.email == "sarah@peersupport.io") {
      setName("Sarah");
    } else if (currentUser.email == "tomurban3@gmail.com") {
      setName("Tom");
    } else if (currentUser.email == "jessica@peersupport.io") {
      setName("Jessica");
    } else if (currentUser.email == "cj@peersupport.io") {
      setName("Chirag");
    } else if (currentUser.email == "crichard@yukonu.ca") {
      setName("Clement");
    } else if (currentUser.email == "pat@panache.vc") {
      setName("Patrick");
    } else if (currentUser.email == "nadia@panache.vc") {
      setName("Nadia");
    } else if (currentUser.email == "alexander@ondeck-ai.com") {
      setName("Honey");
    } else {
      setName("User not recognized");
    }
  }, [currentUser]);

  return (
    <div className="top-bar">
      <span>Hi {name}!</span>
      <div className="logo-container">
        <img src="krasman-2.png" alt="KidsHelpPhone" className="logo" />
      </div>
    </div>
  );
};

export default TopBar;