import React, { useState } from 'react';

const PanelHeader = () => {
    const [collapsed, setCollapsed] = useState(false);

    const handleClick = () => {
        setCollapsed(true);
    };

    return (
        <div className="panel-button-row">
            <div className="child-button" onClick={handleClick}>
                <img src='search.png' alt="Search" />
            </div>
            <div className="child-button">
                <p>Info</p>
            </div>
            <div className="memory-child-button">
                <p>Memory Bank</p>
            </div>
        </div>
    );
};

export default PanelHeader;