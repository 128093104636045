import React from 'react';

const Card = ({ id, title, impact, type, kind, tags, saved, toggleSave, link, toggleLink }) => {
    const fill = saved === "true" ? "#FF6347" : "#A0A2B8"; // Example: Tomato if saved, otherwise grey

    const renderImpact = () => {
        if (impact === "true") {
            return (
                <div className="high-impact-match">
                    <span>High-impact match</span>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="resource-child expanded">
            <div className="resource-header">
                <div className={type === "Resource" ? "resource-icon" : "program-icon"}>{kind}</div>
                {renderImpact()}
            </div>
            <div className="resource-content">
                <div className="title">{title}</div>
                <div className="tags">
                    {tags.map((tag, index) => (
                        <span key={index} className="tag">{tag}</span>
                    ))}
                </div>
                <div className="CTA"> 
                    <div className="icon-link" onClick={() => toggleLink(link)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
                            <path d="M4.78571 6.07141V6.60712C4.78571 6.78465 4.64181 6.92855 4.46429 6.92855H0.821429C0.643906 6.92855 0.5 6.78465 0.5 6.60712V1.67855C0.5 1.50103 0.643906 1.35713 0.821429 1.35713H1.78571V5.32141C1.78571 5.73497 2.12216 6.07141 2.53571 6.07141H4.78571ZM4.78571 1.46427V0.0714111H2.53571C2.35819 0.0714111 2.21429 0.215317 2.21429 0.39284V5.32141C2.21429 5.49893 2.35819 5.64284 2.53571 5.64284H6.17857C6.35609 5.64284 6.5 5.49893 6.5 5.32141V1.7857H5.10714C4.93036 1.7857 4.78571 1.64105 4.78571 1.46427ZM6.40586 1.0487L5.52271 0.16555C5.46243 0.105274 5.38068 0.0714116 5.29543 0.0714111H5.21429V1.35713H6.5V1.27598C6.5 1.19073 6.46614 1.10898 6.40586 1.0487Z" fill="white"/>
                        </svg>
                    </div>
                    <div className="save-link" onClick={() => toggleSave(id)}> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 7 7">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83333 0H5.16667C5.90305 0 6.5 0.600093 6.5 1.34034V5.99283C6.5 6.84181 5.51785 7.3085 4.86526 6.76962L3.5 5.64226L2.13474 6.76962C1.48215 7.3085 0.5 6.84181 0.5 5.99283V1.34034C0.5 0.600093 1.09695 0 1.83333 0ZM1.83333 0.670172C1.46514 0.670172 1.16667 0.970219 1.16667 1.34034V5.99283C1.16667 6.27582 1.49405 6.43139 1.71158 6.25176L3.07684 5.1244C3.32278 4.92132 3.67722 4.92132 3.92316 5.1244L5.28842 6.25176C5.50595 6.43139 5.83333 6.27582 5.83333 5.99283V1.34034C5.83333 0.970219 5.53486 0.670172 5.16667 0.670172H1.83333Z" fill={fill}/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
